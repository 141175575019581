import React, { useEffect, useRef, useState } from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/components/Link';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { IMenuItemProps } from '../../../Menu.types';
import { MenuItemContext } from '../../../../../common/menu/MenuItemContext';
import {
  createEventListeners,
  createSDKAction,
  getVerticalPositionClass,
  setStreachedMegaMenuLeftPosition,
} from './utils';
import classes from './style/MenuItem.scss';
import { testIds } from '../../constants';
import { isCurrentItem } from '../../../../../common/menu/getCurrentMenuItem';
import menuSemanticClassNames from '../../../Menu.semanticClassNames';
import shmSemanticClassNames from '../../../../StylableHorizontalMenu/StylableHorizontalMenu.semanticClassNames';
import DropdownIcon from '../../assets/dropdownIcon.svg';

const itemWrapperClassName = classNames(
  classes.itemWrapper,
  formatClassNames(shmSemanticClassNames.menuItemWrapper),
);

const itemLabelClassName = classNames(
  classes.label,
  formatClassNames(shmSemanticClassNames.menuItemLabel),
  formatClassNames(menuSemanticClassNames.itemLabel),
);

export const MenuItem = (props: IMenuItemProps) => {
  const {
    item,
    currentItem,
    onItemClick,
    onItemDblClick,
    onItemMouseIn,
    onItemMouseOut,
    previewState,
    containerRef,
  } = props;
  const { label, link, children, forceHovered } = item;
  const [isHovered, setIsHovered] = useState(false);
  const [verticalPositionClass, setVerticalPositionClass] = useState('');
  const labelRef = useRef<HTMLAnchorElement | HTMLDivElement>(null);
  const positionBoxRef = useRef<HTMLDivElement>(null);
  const eventListeners = createEventListeners(setIsHovered);

  useEffect(() => {
    if (
      !isHovered ||
      !labelRef.current ||
      !positionBoxRef.current ||
      !containerRef?.current
    ) {
      return;
    }

    const positionBox = positionBoxRef.current;
    setStreachedMegaMenuLeftPosition(containerRef.current, positionBox);
    setVerticalPositionClass(
      getVerticalPositionClass({
        label: labelRef.current,
        positionBox: positionBoxRef.current,
      }),
    );

    return () => {
      positionBox.removeAttribute('style');
    };
  }, [isHovered, positionBoxRef, labelRef, containerRef]);

  useEffect(() => {
    setIsHovered(!!forceHovered);
  }, [forceHovered]);

  const isCurrentPage = isCurrentItem(item, currentItem);

  const itemLabelContainerClassName = classNames(
    classes.labelContainer,
    isCurrentPage ? classes.selected : '',
    formatClassNames(menuSemanticClassNames.item),
  );

  return (
    <li
      className={itemWrapperClassName}
      {...(isHovered && {
        'data-hovered': true,
      })}
      {...eventListeners}
      data-testid={testIds.menuItem}
      data-item-depth="0" // For scrolling,  To know how much items on depth=0
    >
      <Link
        {...link}
        className={classes.itemLink}
        ref={labelRef}
        activateByKey="Enter"
        onClick={createSDKAction(item, isCurrentPage, onItemClick)}
        onMouseEnter={createSDKAction(item, isCurrentPage, onItemMouseIn)}
        onMouseLeave={createSDKAction(item, isCurrentPage, onItemMouseOut)}
        onDoubleClick={createSDKAction(item, isCurrentPage, onItemDblClick)}
        {...(!!children && {
          'aria-haspopup': true,
          ...(!link?.href && {
            role: 'button',
          }),
        })}
        tabIndex={0}
        data-item-label
      >
        <div
          className={itemLabelContainerClassName}
          data-preview={previewState}
          data-testid={testIds.itemLabel}
        >
          <span className={itemLabelClassName}>{label}</span>
          {item.children && <DropdownIcon className={classes.arrow} />}
        </div>
      </Link>
      {children && (
        <div
          className={classes.childrenWrapper}
          role="group"
          aria-label={label}
          data-testid={testIds.childrenWrapper}
        >
          <div
            className={classNames(classes.positionBox, verticalPositionClass)}
            ref={positionBoxRef}
          >
            <div className={classes.alignBox}>
              <MenuItemContext.Provider value={{ item }}>
                {children}
              </MenuItemContext.Provider>
            </div>
          </div>
        </div>
      )}
    </li>
  );
};
